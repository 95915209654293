import {
    GoogleAuthProvider,
    signInWithPopup,
    onAuthStateChanged as _onAuthStateChanged,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    createUserWithEmailAndPassword,
} from "firebase/auth";

import { auth } from "./firebase";

export function onAuthStateChanged(cb) {
    return _onAuthStateChanged(auth, cb);
}

export async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();

    try {
        await signInWithPopup(auth, provider);
    } catch (error) {
        console.error("Error signing in with Google", error);
    }
}

export async function signOut() {
    try {
        return auth.signOut();
    } catch (error) {
        console.error("Error signing out with Google", error);
    }
}

export async function signInWithEmail(email, password, setError){
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        setError("Credenciais inválidas.");
    }
}

export async function createUserWithEmail(name, email, password, setError){
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
        setError("Não foi possível criar a conta com esses dados.")
    }
}

export async function sendPasswordReset(email){
    try{
        await sendPasswordResetEmail(auth,email);
    }catch (error) {
        console.error("Error recovering password", error);
    }
}
