import { Box, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const Footer = () => {

    const menuItems = [
        {title: "Página Principal", href: "/"},
        {title: "Fazer Login", href: "/entrar"},
        {title: "Ferramentas", href: "/painel"}
    ]

    const menu2Items = [
        {title: "Portal de Cursos", href: "https://futebolcursos.com", target: "_blank"},
        {title: "Blog do Categoria", href: "https://categoriacanal.com", target: "_blank"},
        {title: "Núcleo de Análises", href: "https://nucleo.categoriacanal.com", target: "_blank"}
    ]

    return(        
        <Box sx={{ py:3, pb:2, flexGrow: 1, display:'flex', justifyContent: 'center', background:"#000000" }}>
            <Grid container>
                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    {menuItems.map((item,index)=>(
                        <Link key={index} to={item.href} style={{textDecoration: "none"}}>
                            <Typography variant="caption" color="GrayText">
                                {item.title}
                            </Typography>
                        </Link>
                    ))}                    
                </Grid>
                
                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    {menu2Items.map((item,index)=>(
                        <Link key={index} to={item.href} style={{textDecoration: "none"}} target={item.target ? item.target : ""} >
                            <Typography variant="caption" color="GrayText">
                                {item.title}
                            </Typography>
                        </Link>
                    ))}                    
                </Grid>

                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    <Typography variant="caption" color="GrayText">
                        <b>Tools Categoria Canal</b>
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        Precisa de ajuda?
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        contato@categoriacanal.com
                    </Typography>

                </Grid>
                <Grid item xs={12} sx={{pt:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    <Typography variant="caption" color="GrayText">
                        © 2015-2024 Categoria Canal - Todos os direitos reservados.
                    </Typography>
                </Grid>
            </Grid>
            
        </Box>
    )
}

export default Footer;