'use client'
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function Header() {
  const { user, loading, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuNav = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNav = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    handleClose();
    signOut();
  }

  const handleNavigation = (path) => {
    handleClose();
    handleCloseNav();
    navigate(path);
  }

  const menuItems = [
    {
      title: "Ferramentas",
      link: "/painel"
    },{
      title: "Atualizações",
      link: "/updates"
    }
  ];
  
  return (
    <Box sx={{ maxWidth: '100%' }}>
      <AppBar position="fixed">
        <Toolbar>
          <Link style={{ textDecoration: 'none' }} to="/">
            <Typography
              variant="h6"       
              noWrap              
              sx={{ color: 'white', mr: 2, fontWeight:'700', textDecoration: 'none !important' }}
            >
              CATEGORIA CANAL
            </Typography>
          </Link>  
          
          <Box sx={{ display:{xs:'none',sm:'flex'} }}>
            {menuItems.map((item,index)=>(
              <Link key={index} to={item.link}>
                <Button size="small" sx={{ color: 'white'}}>{item.title}</Button>
              </Link>
            ))}            
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display:{xs:'flex',sm:'none'} }}>
            <IconButton
              size="large"
              onClick={handleMenuNav}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-navigation"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNav}
            >
              {menuItems.map((item,index)=>(
                <MenuItem key={index} onClick={()=>{handleNavigation(item.link)}}>{item.title}</MenuItem>
              ))}
            </Menu>
          </Box>

          {user ? (
            <div>
              <IconButton
                size="large"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
              </Menu>
            </div>
          ) : (
            !loading && (
              <Button color="inherit" size="small" variant="outlined" onClick={()=>{handleNavigation("/entrar")}} disableElevation>Entrar</Button>
            )
          )}       
        </Toolbar>
      </AppBar>
      <Box sx={{ height: {xs:'56px', sm:'64px'} }} />
    </Box>
  );
}