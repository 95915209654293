import { getApps, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBZd1PW5fiVuTiiRJu4jNjv5-iDAIWdA0g",
  authDomain: "tools-categoria-canal.firebaseapp.com",
  projectId: "tools-categoria-canal",
  storageBucket: "tools-categoria-canal.appspot.com",
  messagingSenderId: "950773548249",
  appId: "1:950773548249:web:19ef05118d5a2f30506ac8",
  measurementId: "G-QNXRYD3FWT"
};

export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(firebaseApp);
export const analytics = getAnalytics(firebaseApp);