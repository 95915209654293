import React, { Suspense } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { AuthProvider } from './context/AuthContext';

import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import FirebaseAnalytics from "./components/FirebaseAnalytics";

const Entrar = React.lazy(() => import("./pages/entrar/page"));
const Painel = React.lazy(() => import("./pages/painel/page"));

const MatrizDecisao = React.lazy(() => import("./pages/matriz-decisao/page"));
const LinhaTempo = React.lazy(() => import("./pages/linha-tempo/page"));
const MapaAcoes = React.lazy(() => import("./pages/mapa-acoes/page"));
const DRAFNodes = React.lazy(() => import("./pages/draf-nodes/page"));

const RedeemBonus = React.lazy(() => import('./pages/redeem-bonus/page'));

const Updates = React.lazy(() => import('./pages/updates/page'));

function App() {
  return (
    <AuthProvider>
      <Router>    
        <FirebaseAnalytics />      
        <Header />
        <Suspense fallback={<Loader />}>
          
          <Routes>

            <Route path="/" element={<Painel />} />
            <Route path="/entrar" element={<Entrar />} />
            <Route path="/painel" element={<Painel />} />
            
            <Route path="/matriz-decisao" element={<MatrizDecisao />} />
            <Route path="/linha-tempo" element={<LinhaTempo />} />
            <Route path="/mapa-acoes" element={<MapaAcoes />} />
            <Route path="/draf-nodes" element={<DRAFNodes />} />

            <Route
              path="/redeemBonus/:bonusUid"
              element={
                <Suspense fallback={<></>}>
                  <RedeemBonus />
                </Suspense>
              }
            />

            <Route path="/*" element={<Painel />} />
            <Route path="/updates" element={<Updates />} />

          </Routes>

        </Suspense>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
